// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './component/layout';
import HomePage from './Pages/HomePage';
import AboutUs from './Pages/AboutUs';
import ShopperrPlusPage from './Pages/ShopperrPlus';
import ShopperrDirect from './Pages/ShopperrDirect';

function App() {
    return (
        <Router>
            <Layout>
                <Routes>                 
                    <Route path="/" element={<HomePage />} />
                    <Route path="/about" element={<AboutUs />} />
                    <Route path='/express' element={<ShopperrPlusPage />} />
                    <Route path='/direct' element={<ShopperrDirect />} />
                </Routes>
            </Layout>
        </Router>
    );
}

export default App;
