import React from "react";
import { Link } from "react-router-dom";

const PlusDirect = () => {
    return (
        <>
            <div className="bg-[#06184b] px-[5%] py-[5%] my-[5%] text-center">
                <h2 className="text-[#62c8f5] text-xl md:text-xl lg:text-4xl sm:text-xl mb-5 font-bold">Shopperr Direct</h2>
                <p className="text-white text-base md:text-base lg:text-lg sm:text-base mb-5 w-full md:w-full lg:w-[50%] sm:w-full m-auto"> 
                Sell on Amazon, Flipkart, Meesho or your shopify website with our huge range of products without owning any stock.
                </p>
                <Link to="/" className="inline-block text-white bg-[#48AF1F] px-[3%] py-3 rounded-lg uppercase">Lets Start</Link>
            </div>                      
            <div className="px-[5%] w-full">
                <img src="plus-direct-img-1.png" alt="" className="w-full" />
            </div>
        </>
    );
}

export default PlusDirect;