import React from "react";

const AboutContent = () =>{
    return (
        <>
        <div className="px-[5%] w-full md:w-full lg:w-[60%] sm:w-full m-auto my-10">
            <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-10 text-center font-bold">About Us</h2>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5"><b>ShopperrGlobal.com</b> is a fully owned subsidiary of Tazah Technologies Pvt Ltd a Singaporean company with interests in agri-businesses, cross-border trade, e-commerce and logistics.</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Shopperr was founded with a vision of <i>“Enabling the <b>next million entrepreneurs</b> to increase their incomes and live better lives”</i></p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Through a team spread across the globe, Shopperr is able to source breakout products directly at OEM prices from different parts of the world including China, India, Pakistan and UAE. These best-selling products have high demand in UAE & KSA enabling resellers to make decent returns and increase their incomes.</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">Based on demand planning and forecast, Shopperr holds stock for some of the items while some items are sourced based on demand. This sophisticated planning allows Shopperr to ensure high service levels while remaining light on inventory</p>
            <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">A strong on-ground team helps with efficient warehousing, fulfillment and logistics, thereby reducing the effective price to the customers and high service levels.</p>
        </div>
        </>
    );
}

export default AboutContent;    