import React from "react";
import { Link } from "react-router-dom";

const LeftRightImageSection = () => {   
    return (
        <>
        <div className="px-[5%] w-full m-auto flex justify-between items-center my-5 flex-wrap gap-y-10">
            <div className="w-full md:w-full lg:w-[48%] sm:w-full order-1">
                <img src="left-img1.png" alt="" className="w-full" />
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full group order-2">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Sourcing
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                    Say goodbye to supplier stress! Shopper's extensive network of suppliers
                    streamlines the process, handling everything from sourcing to delivery. 
                    Enjoy a fully customizable experience, with complete peace of mind
                </p>
                <ul className="hidden text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5 list-disc ml-5">
                    <li>China</li>
                    <li>UAE</li>
                    <li>Saudia Arabia</li>
                </ul>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full group order-4 md:order-4 lg:order-3 sm:order-4">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Shipping & Customs Clearance
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                    <b>Leave the logistics to us!</b> We'll guide your goods through customs and take care
                    of all tha paperwork, making sure your inventory arrives at your desired location 
                    without a hitch.
                </p>
                <ul className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5 list-disc ml-5">
                    <li>Import from <b>International Supppliers</b></li>
                </ul>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full order-3 md:order-3 lg:order-4 sm:order-3">
                <img src="right-img1.png" alt="" className="w-full" />
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full order-5">
                <img src="left-img2.png" alt="" className="w-full" />
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full group order-6">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Warehousing & Fulfilment
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                Enjoy seamless inventory management with our comprehensive warehouse network.
                Our cutting-edge system keeps track of every product detail, from storage and tracking
                to shipping and returns, all accessible through a single, intuitive dashboard
                </p>
                <ul className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5 list-disc ml-5">
                    <li>Warehouse in <b>Shenzen, Yiwu, Guangdong (China)</b></li>
                    <li>Warehouse in <b>Gurgaon (India)</b></li>
                </ul>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full group order-8 md:order-8 lg:order-7 sm:order-8">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Order Tracking
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                Use Shopperr's tech enabled platform to track orders, shipment & payments.
                </p>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full order-7 md:order-7 lg:order-8 sm:order-7">
                <img src="right-img2.png" alt="" className="w-full" />
            </div>
            <div className="w-full hidden md:w-full lg:w-[48%] sm:w-full order-9">
                <img src="left-img3.png" alt="" className="w-full min-h-[300px] md:min-h-[300px] lg:min-h-[600px] sm:min-h-[300px] object-cover" />
            </div>
            <div className="w-full hidden md:w-full lg:w-[48%] sm:w-full group order-10">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Delivery to your Customer
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                <b>Trust Shopperr</b> for secure and efficient <b>door-to-door delivery services,</b>
                tailored to meet the needs of businesses both big and small. Our top-notch security protocols
                ensure the safe handling and storage of your goods, giving you confidence in our services
                - all at a price that fits your budget.
                </p>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full group order-12 md:order-12 lg:order-11 sm:order-12">
                <h2 className="text-[#06184b] text-lg md:text-xl lg:text-4xl sm:text-lg mb-5 font-bold relative w-[max-content]">
                    Ex-China Prices
                    <span className="absolute bottom-[-5px] left-0 w-0 h-[2px] bg-[#06184b] transition-all duration-300 ease-in-out group-hover:w-full"></span>
                </h2>
                <p className="text-[#06184b] text-base md:text-md lg:text-lg smLtext-base mb-5">
                Want to buy large quantities? Get Ex-China quotes choose your own logistics or shopperr 
                logistics to get your shipment.
                </p>
                <Link to="/" className="inline-block text-white bg-[#06184b] px-[5%] py-3 rounded-lg uppercase">Lets Start?</Link>
            </div>
            <div className="w-full md:w-full lg:w-[48%] sm:w-full order-11 md:order-11 lg:order-12 sm:order-11">
                <img src="right-img3.jpg" alt="" className="w-full" />
            </div>
        </div>
        </>
    );
}

export default LeftRightImageSection;