import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {  
    return (
        <>
            <footer className="bg-[#06184b] px-[5%] pt-[5%] flex flex-wrap justify-between gap-y-10">
                <div className="w-full md:w-full lg:w-[25%] sm:w-full">
                    <h2 className="text-sm md:text-md lg:text-lg sm:text-sm text-[#62c8f5] uppercase font-bold">Follow Us:</h2>
                    <div className="flex items-center gap-5 mt-3">
                        <Link className="text-white text-base md:text-md lg:text-lg sm:text-base" to="/"><i class="fa-brands fa-facebook-f"></i></Link>
                        <Link className="text-white text-base md:text-md lg:text-lg sm:text-base" to="/"><i class="fa-brands fa-whatsapp"></i></Link>
                        <Link className="text-white text-base md:text-md lg:text-lg sm:text-base" to="/"><i class="fa-brands fa-instagram"></i></Link>
                    </div>
                </div>
                <div className="w-full md:w-[50%] lg:w-[35%] sm:full">
                    <h2 className="text-base md:text-md lg:text-lg sm:text-base text-[#62c8f5] uppercase font-bold">Support</h2>
                    <ul className="mt-3 flex flex-col gap-2">
                        <li><Link className="text-sm md:text-base lg:text-md sm:text-sm text-white" to="/">Refund & Replacement Policy (For Resellers)</Link></li>
                        <li><Link className="text-sm md:text-base lg:text-md sm:text-sm text-white" to="/">Refund & Replacement Policy (Customers)</Link></li>
                        <li><Link className="text-sm md:text-base lg:text-md sm:text-sm text-white" to="/">Privacy Policy</Link></li>
                        <li><Link className="text-sm md:text-base lg:text-md sm:text-sm text-white" to="/">Terms of Service</Link></li>
                    </ul>
                </div>
                <div className="w-full md:w-[50%] lg:w-[35%] sm:w-full">
                    <h2 className="text-base md:text-md lg:text-lg sm:text-base text-[#62c8f5] uppercase font-bold">Powered by</h2>
                    <ul className="mt-3 flex flex-col gap-2">
                        <li className="text-sm md:text-base lg:text-md sm:text-sm text-white">Shopperr is powered and owned by Tazah Global L.L.C-FZ</li>
                        <li className="text-sm md:text-base lg:text-md sm:text-sm text-white">Warehouse # 13, Plot # 4488, PO Box 5841, AI Sajaa Industrial, Sharjah</li>
                    </ul>
                </div>
                <div className="w-full py-4 mt-[5%] border-t border-white">
                    <p className="text-sm md:text-base lg:text-md sm:text-sm text-white">2024, Shopperr Powered by Shopify</p>
                </div>
            </footer>
        </>
    );
}

export default Footer;